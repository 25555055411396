import { merge, ThemeUIStyleObject } from "theme-ui";
import tailwind from "@theme-ui/preset-tailwind";

declare module "theme-ui" {
  interface Theme {
    footer?: ThemeUIStyleObject;
    texts?: ThemeUIStyleObject;
    header?: ThemeUIStyleObject;
    textContents?: ThemeUIStyleObject;
    nav?: ThemeUIStyleObject;
    ul?: ThemeUIStyleObject;
    li?: ThemeUIStyleObject;
    slidediv?: ThemeUIStyleObject;
    contactform?: ThemeUIStyleObject;
    artistcardback?: ThemeUIStyleObject;
    artistdetail?: ThemeUIStyleObject;
    artisticon?: ThemeUIStyleObject;
    artistinformation?: ThemeUIStyleObject;
    artistinformationlist?: ThemeUIStyleObject;
    artistinformationH3?: ThemeUIStyleObject;
    artistinformationWorksThumbnail?: ThemeUIStyleObject;
    borderBtn02?: ThemeUIStyleObject;
    priceTable?: ThemeUIStyleObject;
    headerNav?: ThemeUIStyleObject;
    menuButton?: ThemeUIStyleObject;
    newsRelease?: ThemeUIStyleObject;
    newsReleasePicture?: ThemeUIStyleObject;
    newsReleaseDate?: ThemeUIStyleObject;
    newsReleaseDetails?: ThemeUIStyleObject;
    pageTitle?: ThemeUIStyleObject;
    workPlan?: ThemeUIStyleObject;
    workPlanSub?: ThemeUIStyleObject;
    snip1344?: ThemeUIStyleObject;
    worksSlide: ThemeUIStyleObject;
    planIntroductionOnToppage: ThemeUIStyleObject;
    categoryCard: ThemeUIStyleObject;
  }
}

const theme = merge(tailwind, {
  config: {
    initialColorModeName: `light`,
  },
  // colors: {
  //   primary: tailwind.colors.orange[4],
  //   secondary: tailwind.colors.indigo[6],
  //   text: tailwind.colors.gray[3],
  //   heading: tailwind.colors.white,
  //   background: `#141821`,
  //   divider: tailwind.colors.gray[8],
  //   textMuted: tailwind.colors.gray[5],
  //   icon_brightest: tailwind.colors.white,
  //   icon_darker: tailwind.colors.gray[7],
  //   icon_darkest: tailwind.colors.gray[8],
  //   icon_red: tailwind.colors.red[6],
  //   icon_blue: tailwind.colors.blue[6],
  //   icon_orange: tailwind.colors.orange[5],
  //   icon_yellow: tailwind.colors.yellow[5],
  //   icon_pink: tailwind.colors.pink[5],
  //   icon_purple: tailwind.colors.purple[6],
  //   icon_green: tailwind.colors.green[5],
  //   icon_1: '#441495',
  //   icon_2: '#441495',
  //   icon_3: '#441495',
  //   header: '#170733',
  // },
  colors: {
    text: tailwind.colors.black,
    heading: tailwind.colors.black,
    primary: tailwind.colors.black,
    background: tailwind.colors.gray[1],
    divider: tailwind.colors.gray[2],
    textMuted: tailwind.colors.gray[6],
    icon_brightest: tailwind.colors.gray[2],
    icon_darker: tailwind.colors.gray[4],
    icon_darkest: tailwind.colors.gray[6],
    icon_red: tailwind.colors.red[6],
    icon_blue: tailwind.colors.blue[6],
    icon_orange: tailwind.colors.orange[5],
    icon_yellow: tailwind.colors.yellow[5],
    icon_pink: tailwind.colors.pink[5],
    icon_purple: tailwind.colors.purple[6],
    icon_green: tailwind.colors.green[5],
    icon_1: '#441495',
    icon_2: '#441495',
    icon_3: '#441495',
    header: '#170733',
  },
  breakpoints: [`400px`, `600px`, `900px`, `1200px`, `1600px`],
  styles: {
    root: {
      fontFamily:"Noto Sans JP,sans-serif",
      margin: 0,
      padding: 0,
      boxSizing: `border-box`,
      textRendering: `optimizeLegibility`,
      WebkitFontSmoothing: `antialiased`,
      MozOsxFontSmoothing: `grayscale`,
      color: `text`,
      backgroundColor: `background`,
      WebkitTextSizeAdjust: `100%`,
      display: "grid",

      a: {
        color: `primary`,
        textDecoration: `none`,
        transition: `all 0.3s ease-in-out`,
        "&:hover": {
          color: `primary`,
          textDecoration: `none`,
        },
      },
      img: {
        borderStyle: `none`,
      },
      pre: {
        fontFamily: `monospace`,
        fontSize: `1em`,
      },
      h2 :{
        fontWeight: '400',
      }
    },
    p: {
      fontSize: ['1rem','1.25rem','1.5rem'],
      letterSpacing: "-0.003em",
      lineHeight: "body",
      "--baseline-multiplier": "0.179rem",
      "--x-height-multiplier": "0.35rem",
      color: "text",
    },
    blockquote: {
      marginLeft: 0,
      p: {
        fontSize: ["2rem", "3rem"],
        fontWeight: "medium",
        color: "heading",
      },
    },
    h1: {
      fontSize: ["3rem", "4rem", "4.5rem"],
      mt: "2rem",
      mb: "3rem",
      textShadow: "2px 2px 2px gray",
      letterSpacing: "wide",
      color: "heading",
      fontWeight: '500',
    },
    h2: {
      fontSize: ["1.875rem" ,"2.25rem", "3rem"],
      mt: "0.5rem",
      mb: "0.55rem",
      color: "heading",
    },
    h3: {
      fontSize: ["1.5rem", "2rem", "2.5rem"],
      mt: "1rem",
      color: "heading",
    },
    h4: {
      fontSize: ["1rem", "1.5rem", "2rem"],
      color: "heading",
    },
    h5: {
      fontSize: ["0.5rem", "1rem", "1.5rem"],
      color: "heading",
    },
    h6: {
      fontSize: "0.5rem",
      mb: "2rem",
      color: "heading",
    },
  },
  layout: {
    container: {
      maxWidth: `5xl`,
    },
  },
  main: {
    alignItems: 'center',
    width: "100%",
    display: "grid",
  },
  buttons: {
    toggle: {
      color: "background",
      border: "none",
      backgroundColor: "text",
      cursor: "pointer",
      alignSelf: "center",
      px: "3rem",
      py: "2rem",
      ml: "3rem",
    },
  },
  footer: {
    textAlign: "center",
    display: "block",
    color: "textMuted",
    px: ["1rem", "1.5rem"],
    py: ["1.25rem", "1.5rem"],
    fontSize: ['0.75rem','1rem','1.25rem'],
    marginTop:'0.5rem',
    nav: {
      display: 'flex',
      alignItems: 'center',
    },
    ul: {
      listStyle: 'none',
      padding: 0,
      display: 'grid',
      gap: '10px',
      width: '100%',
      gridTemplateColumns: 'repeat(5, auto)',
    },
    li: {
      textAlign: 'center',
      padding: 'auto',
      fontSize: 'inherit',
    }
  },
  texts: {
    bigger: {
      p: {
        fontSize: ["2rem", "2.5rem", "3rem"],
      },
    },
  },
  header: {
    height: '12vh',
    padding: '0',
    alignItems: 'center',
    minHeight: '12vh',
    width: '100%',
    zIndex: 5,
    nav: {
      display: 'block',
      ul: {
        listStyle: 'none',
        width: '100%',
        margin:0,
      },
      li: {
        textAlign: 'left',
        padding: 'auto',
      },
      a: {
        color: '#000',
        fontWeight: 'bold',
        textDecoration: 'none',
      },
    },
    '@media screen and (min-width: 769px)': {
      gridTemplateColumns: '10vw 30vw',
      display: 'grid',
      nav: {
        padding: '8px',
        height: '8vh',
        alignItems: 'center',
        ul: {
          display: 'flex',
          flexFlow: 'column wrap',
          gap: '10px',
          height: '8vh',
          padding: 0,
          width: '100%',
        },
        li: {
          padding: '8px 5px',
          height: '33.3%',
        },
        a: {
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    '@media screen and (max-width: 768px)': {
      display: 'flex',
      nav: {
        height: '100vh',
        width: '100vw',
        position: `absolute`,
        top: '0',
        left: '0',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: `background`,
        opacity: 0.8,
        ul: {
          listStyle: 'none',
          width: '100%',
        },
        li: {
          borderBottom: 'solid 2px',
          width: '100%',
          marginTop: '5vh',
        },
      },
    },
  },
  menuButton: {
    position: 'absolute',
    backgroundColor: `background`,
    cursor: 'pointer',
    width: '50px',
    height: '50px',
    borderRadius: '5px',
    display: 'block',
    right: '5vw',
    top:'10px',
    zIndex:'15',
    span: {
      display: 'inline-block',
      transition: 'all 0.4s',
      position: 'absolute',
      left: '14px',
      height: '3px',
      borderRadius: '0.5px',
      backgroundColor: '#000',
      width: '45%',
      '&:nth-of-type(1)': {
        top: '15px',
      },
      '&:nth-of-type(2)': {
        top: '23px',
      },
      '&:nth-of-type(3)': {
        top: '31px',
      },
    },
    '&.active': {
      backgroundColor: `background`,
      opacity:0.8,
      span: {
        '&:nth-of-type(1)': {
          top: '18px',
          left: '18px',
          transform: 'translateY(6px) rotate(-45deg)',
          width: '30%',
        },
        '&:nth-of-type(2)': {
          opacity: 0,
        },
        '&:nth-of-type(3)': {
          top: '30px',
          left: '18px',
          transform: 'translateY(-6px) rotate(45deg)',
          width: '30%',
        },
      },
    },
  },
  li: {
    margin: '0 0 0 15',
    fontSize: '14'
  },
  slidediv: {
    width: '100%',
    maxHeight: '70vh',
    top: '0',
    display: 'flex',
    zIndex: 5,
    marginTop: '8vh',
  },
  contactform: {
    width: '80vw',
  },
  artistcardback: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    margin: 'auto',
    zIndex: '-1',
  },
  artistdetail: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: '20px',
    marginTop: '8vh',
  },
  artisticon: {
    padding: '20px',
    width: '40vw',
    minWidth: '300px',
  },
  artistinformation: {
    padding: '20px',
    width: '40vw',
    minWidth: '300px',
    h2: {
      fontSize: [20, 30, 40],
      color: `heading`,
      position: 'relative',
      margin: 0,
    },
    p: {
      width :['20px', '30px', '40px'],
      marginLeft:'2.5%',
      paddingTop: '5px',
      fontSize: [10, 12, 14],
    },
    ul: {
      listStyle: 'none',
      paddingLeft: '0'
    },
    table: {
      width: '90%',
      textAlign: 'center',
      borderCollapse: 'collapse',
      borderTop: 'solid 1px #778ca3',
      fontSize: [10,15,20],
      tableLayout: 'fixed',
      'th':{
        color:'#936cc6',
      },
      'th, td': {
        textAlign: 'left',
        padding: '5px',
        borderBottom: 'solid 1px #778ca3',
      },
    },
  },
  artistinformationlist: {
    listStyle: 'none',
  },
  artistinformationH3: {
    h3: {
      margin: 0,
      textAlign: 'left',
      padding: '5px',
      width:100,
      color:'#936cc6',
      fontSize: [10,15,20],
    },
    width: '90%',
    padding: '5px',
    borderBottom: 'solid 1px #778ca3',
  },
  artistinformationWorksThumbnail:{
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: 0,
    div: {
      margin: '2.5%',
      width:'45%',
      backgroundColor:'#778ca3',
      minWidth:'150px'
    }
  },
  borderBtn02: {
    fontSize: ['20pt','30pt','40pt'],
    fontWeight: '400',
    display: 'inline-block',
    width: '100%',
    maxWidth: '80vw', // ボタン幅
    position: 'relative',
    color: tailwind.colors.red[6], // 文字色
    padding: '0.5em 0.5em',
    textDecoration: 'none',
    textAlign: 'center',
    transition: '0.3s',
    '::before': {
      position: 'absolute',
      width: '100%',
      height: '2px', // 線幅
      content: '""',
      transition: 'all .3s',
      background: tailwind.colors.red[6], // 線色
      top: 0,
      left: 0,
    },
    '::after': {
      position: 'absolute',
      width: '100%',
      height: '2px', // 線幅
      content: '""',
      transition: 'all .3s',
      background: tailwind.colors.red[6], // 線色
      right: 0,
      bottom: 0,
    },
    '&:hover::before, &:hover::after': {
      width: '0',
    },
  },
  newsRelease: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: '20px',
    marginTop: '20px',
  },
  newsReleaseDate: {
    width:['140px','160px','200px'],
    margin: '1em 0',
    position: 'relative',
    padding: '0.5em 1.5em',
    borderTop: 'solid 2px #936cc6',
    borderBottom: 'solid 2px #936cc6',
    textAlign:'center',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '-10px',
      width: '2px',
      height: 'calc(100% + 20px)',
      backgroundColor: '#936cc6',
    },
    '&:before': {
      left: '10px',
    },
    '&:after': {
      right: '10px',
    },
    p: {
      margin: 0,
      padding: 0,
      fontSize: [12, 14, 20],

    },
  },
  newsReleasePicture:{    
    marginTop:['0px','0px','0px','90px'],
    padding: '20px',
    width: '20vw',
    height:'12vw',
    maxWidth: '400px',
    maxHeight: '240px',
    minWidth:'300px',
    minHeight:'180px'
  },
  newsReleaseDetails:{    
    padding: '20px',
    width: '20vw',
    maxWidth: '400px',
    minWidth:'300px',
    P: {
      fontSize: [12, 14, 20],
      mt: 2,
      mb: 2,
      color: `heading`,
      position: 'relative',
    },
    table: {
      margin: '20px auto',
      width: '90%',
      textAlign: 'center',
      borderCollapse: 'collapse',
      borderSpacing: 0,
      borderTop: 'solid 1px #778ca3',
      fontSize: [10,15,20],
      tableLayout: 'fixed',
      'th':{
        color:'#936cc6',
      },
      'th, td': {
        textAlign: 'left',
        padding: '5px',
        borderBottom: 'solid 1px #778ca3',
      },
    },
  },
  pageTitle:{
    textAlign:'center',
    h1: {
      fontSize: [30, 40, 50],
      color: `heading`,
      position: 'relative',
      textAlign: 'center',
      span: {
        position: 'relative',
        zIndex: 2,
      },
      '::before': {
        content: 'attr(data-en)',
        position: 'absolute',
        top: '-20px',
        left: '50%',
        transform: 'translateX(-50%)',
        color: '#936cc650',
        fontSize: '40px',
        fontStyle: 'italic',
      },
    },
  },
  priceTable: {
      margin: '0 auto',
      marginBottom:'4vh',
      width: '80%',
      textAlign: 'center',
      borderCollapse: 'collapse',
      borderSpacing: 0,
      borderTop: 'solid 1px #778ca3',
      fontSize: [10,15,20],
      tableLayout: 'fixed',
      'th':{
        color:'#936cc6',
      },
      'th, td': {
        textAlign: 'left',
        padding: '5px',
        borderBottom: 'solid 1px #778ca3',
      },
    },
  workPlan: {
    margin: '0 auto',
    flexWrap: 'wrap',
    textAlign: 'center',
    h2: {
      textAlign: 'center',
      padding: 0,
      fontSize: [20,25,30]
    },
    h3: {
      marginLeft:'10%',
      textAlign: 'left',
      padding: 0,
      fontSize: [10,15,20],
    },
    summary: {
      marginLeft:'10%',
      textAlign: 'left',
      padding: 0,
      fontSize: [10,15,20],
    },
    h4: {
      margin:'auto 10% auto 10%',
      textAlign: 'left',
      padding: 0,
      fontSize: [10,13,15],
    }
  },
  snip1344: {
    fontFamily: 'Open Sans, Arial, sans-serif',
    position: 'relative',
    overflow: 'hidden',
    margin:'auto',
    minWidth: '230px',
    minHeight: '350px',
    maxWidth: '400px',
    maxHeight: '400px',
    width: '50vw',
    color: '#ffffff',
    textAlign: 'center',
    lineHeight: '1.4em',
    backgroundColor: '#141414',
    '*': {
      boxSizing: 'border-box',
      transition: 'all 0.25s ease',
    },
    '.background': {
      width: '100%',
      verticalAlign: 'top',
      opacity: '0.2',
      filter: 'grayscale(100%) blur(10px)',
      transition: 'all 2s ease',
    },
    figcaption: {
      width: '100%',
      padding: '15px 25px',
      position: 'absolute',
      left: '0',
      top: '45%',
    },
    '.profile': {
      borderRadius: '50%',
      position: 'absolute',
      bottom: ['60%','60%','55%'],
      left: '50%',
      width: '20vw',
      minWidth: '100px',
      maxWidth: '40%',
      opacity: '1',
      boxShadow: '3px 3px 20px rgba(0, 0, 0, 0.5)',
      border: '2px solid rgba(255, 255, 255, 0.5)',
      transform: 'translate(-50%, 0%)',
    },
    h3: {
      fontSize: [15,20,40],
      margin: '0 0 5px',
      fontWeight: '400',
      span: {
        display: 'block',
        fontSize: [9,12,24],
        color: '#f39c12',
        opacity: '0.75',
        marginTop: ['0px', '0px', '20px'],
        marginBottom: ['0px', '0px', '30px']
      },
    },
    i: {
      padding: '10px 5px',
      display: 'inline-block',
      fontSize: '32px',
      color: '#ffffff',
      textAlign: 'center',
      opacity: '0.65',
      ':hover': {
        opacity: '1',
        transition: 'all 0.35s ease',
      },
    },
    p: {
      textAlign:'left',
      mb:[10, 10, 15],
      margin: '0 5px 5px',
      fontWeight: '400',
      fontSize: [13,15,20],
    },
    a: {
      textDecoration: 'none',
    },
    ':hover .background, .hover .background': {
      transform: 'scale(1.3)',
    },
  },
  textContents: {
    margin: '0 10vw',
    ul: {
      fontSize:['1rem','1.25rem','1.5rem'],
    },
    table: {
      margin: '0 auto',
      marginTop:'100px',
      marginBottom:'4vh',
      width: '100%',
      textAlign: 'center',
      borderCollapse: 'collapse',
      borderSpacing: 0,
      borderTop: 'solid 1px #778ca3',
      fontSize: [10,15,20],
      tableLayout: 'fixed',
      'th, td': {
        textAlign: 'left',
        padding: '10px',
        borderBottom: 'solid 1px #778ca3',
      },
    },
  },
  worksSlide: {
    overflow:'hidden',
    ul:{
      listStyle: 'none',
      display: 'flex',
      marginBlockStart: '1em',
      marginBlockEnd: '1em',
      marginInlineStart: '0px',
      marginInlineEnd: '0px',
      paddingInlineStart: '0px',
      unicodeBidi: 'isolate',
      animationDuration: '50s',
      animationTimingFunction: 'linear',
      animationDelay: '0s',
      animationIterationCount: 'infinite',
      animationDirection: 'normal',
      animationFillMode: 'none',
      animationPlayState: 'running',
      animationName: 'slide',
      animationTimeline: 'auto',
      animationRangeStart: 'normal',
      animationRangeEnd: 'normal',
      willChange: 'transform',
    },
    '@keyframes slide': {
      '0%': {
        transform: 'translateX(0)',
      },
      '100%': {
        transform: 'translateX(-50%)',
      },
    },
    li:{
      position: 'relative',
      padding: '0px',
      marginRight: '0px',
      lineHeight: 0,
    },
    '@media screen and (min-width: 769px)': {
      li:{
        height: '14vw',
      },
    },
    '@media screen and (max-width: 768px)': {
      li:{
        height: '30vw',
      },
    }
  },
  worksOnToppage:{
    mt:'5vh',
    h2: {
      fontSize: ['20pt','30pt','40pt'],
      ml: '10vw',
    },
    h3: {
      ml: '10vw',
      fontSize: [20,25,'25pt'],
      textDecoration: 'underline',
    },
  },
  planIntroductionOnToppage:{
    padding: "0 10vw",
    h2: {
      fontSize: ['20pt','30pt','40pt'],
    },
  },
  categoryCard:{
    display:'grid',
    textAlign: "center",
    a: {
      width: ['23vw','23vw','15vw'],
      height: ['23vw','23vw','15vw'],
      boxShadow: `lg`,
      position: `relative`,
      textDecoration: `none`,
      color: `white`,
      transition: `all 0s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important`,
      margin: 'auto',
      "&:hover": {
        color: `white !important`,
        transform: `translateY(-5px)`,
        boxShadow: `xl`,
      },
    },
    p:{
      fontWeight:"bold",
      margin:0
    }
  },

});

export default theme;
