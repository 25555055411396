exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-acappella-mix-job-request-form-sonoi-tsx": () => import("./../../../src/pages/acappellaMixJobRequestFormSonoi.tsx" /* webpackChunkName: "component---src-pages-acappella-mix-job-request-form-sonoi-tsx" */),
  "component---src-pages-acappella-score-compose-job-request-form-sonoi-tsx": () => import("./../../../src/pages/acappellaScoreComposeJobRequestFormSonoi.tsx" /* webpackChunkName: "component---src-pages-acappella-score-compose-job-request-form-sonoi-tsx" */),
  "component---src-pages-artist-tsx": () => import("./../../../src/pages/artist.tsx" /* webpackChunkName: "component---src-pages-artist-tsx" */),
  "component---src-pages-contact-sonoi-tsx": () => import("./../../../src/pages/contactSonoi.tsx" /* webpackChunkName: "component---src-pages-contact-sonoi-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contactUs.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-form-thanks-page-tsx": () => import("./../../../src/pages/formThanksPage.tsx" /* webpackChunkName: "component---src-pages-form-thanks-page-tsx" */),
  "component---src-pages-instrumental-job-request-form-tsx": () => import("./../../../src/pages/instrumentalJobRequestForm.tsx" /* webpackChunkName: "component---src-pages-instrumental-job-request-form-tsx" */),
  "component---src-pages-ir-tsx": () => import("./../../../src/pages/ir.tsx" /* webpackChunkName: "component---src-pages-ir-tsx" */),
  "component---src-pages-job-request-flow-tsx": () => import("./../../../src/pages/jobRequestFlow.tsx" /* webpackChunkName: "component---src-pages-job-request-flow-tsx" */),
  "component---src-pages-mix-job-request-form-sonoi-tsx": () => import("./../../../src/pages/mixJobRequestFormSonoi.tsx" /* webpackChunkName: "component---src-pages-mix-job-request-form-sonoi-tsx" */),
  "component---src-pages-music-composition-job-request-form-tsx": () => import("./../../../src/pages/musicCompositionJobRequestForm.tsx" /* webpackChunkName: "component---src-pages-music-composition-job-request-form-tsx" */),
  "component---src-pages-other-request-form-tsx": () => import("./../../../src/pages/otherRequestForm.tsx" /* webpackChunkName: "component---src-pages-other-request-form-tsx" */),
  "component---src-pages-partner-introduction-tsx": () => import("./../../../src/pages/partner-introduction.tsx" /* webpackChunkName: "component---src-pages-partner-introduction-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacyPolicy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-produce-form-tsx": () => import("./../../../src/pages/produceForm.tsx" /* webpackChunkName: "component---src-pages-produce-form-tsx" */),
  "component---src-pages-specified-commercial-transactions-tsx": () => import("./../../../src/pages/specifiedCommercialTransactions.tsx" /* webpackChunkName: "component---src-pages-specified-commercial-transactions-tsx" */),
  "component---src-pages-top-tsx": () => import("./../../../src/pages/top.tsx" /* webpackChunkName: "component---src-pages-top-tsx" */),
  "component---src-pages-topics-tsx": () => import("./../../../src/pages/topics.tsx" /* webpackChunkName: "component---src-pages-topics-tsx" */),
  "component---src-pages-voice-drama-request-form-tsx": () => import("./../../../src/pages/voiceDramaRequestForm.tsx" /* webpackChunkName: "component---src-pages-voice-drama-request-form-tsx" */),
  "component---src-pages-workertest-tsx": () => import("./../../../src/pages/workertest.tsx" /* webpackChunkName: "component---src-pages-workertest-tsx" */)
}

